<template>
  <div class="sales salesHeader-box">
    <div class="w1 center_all" v-loading="handleLoading">
      <div class="center_1 posion">
        <ul class="center_1_select clearfix">
          <li>
            <span>选择刊物</span>
            <el-select v-model="magId" placeholder="请选择" @change="selection()">
              <el-option
                v-for="item in magOptions"
                :key="item.magId"
                :label="item.magName"
                :value="item.magId">
              </el-option>
            </el-select>
          </li>
          <li>
            <span>状态</span>
            <el-select v-model="statusId" placeholder="请选择" @change="selection()">
              <el-option
                v-for="item in statusOptions"
                :key="item.id"
                :label="item.course"
                :value="item.id">
              </el-option>
            </el-select>
          </li>
          <li>
            <span>数据源</span>
            <el-select v-model="dataSourceId" placeholder="请选择" @change="selection()">
              <el-option
                v-for="item in sourceOptions"
                :key="item.dataSourceId"
                :label="item.dataSourceName"
                :value="item.dataSourceId">
              </el-option>
            </el-select>
          </li>
          <el-button style="float:right;" type="primary" icon="el-icon-plus" @click="add()">新建</el-button>
        </ul>
        <el-table :data="tableData" border style="width: 100%" :header-cell-style="{background: '#f3f4f8;'}" :row-class-name="tableRowClassName">
          <el-table-column align="center" type="index" label="序号"></el-table-column>
          <el-table-column align="center" prop="magName" label="刊物"></el-table-column>
          <el-table-column align="center" prop="dataSource" label="数据源"></el-table-column>
          <el-table-column align="center" prop="createTime" label="申请时间"></el-table-column>
          <el-table-column align="center" prop="planCompletedTime" label="计划完成时间"></el-table-column>
          <el-table-column align="center" prop="latestIssue" label="当前最新刊期"></el-table-column>
          <el-table-column align="center" prop="publishPeriod" label="出版周期"></el-table-column>
          <el-table-column align="center" prop="predictedIssue" label="预测当前刊期"></el-table-column>
          <el-table-column align="center" prop="taskTrigger" label="采集周期"></el-table-column>
          <el-table-column align="center" prop="lastCrawlTime" label="上次采集时间"></el-table-column>
          <el-table-column align="center" prop="taskUrl" label="工单地址">
            <template slot-scope="scope">
              <el-button :disabled="!scope.row.taskUrl" @click="handleClick(scope.row, 'taskUrl')" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="siteUrl" label="网站地址">
            <template slot-scope="scope">
              <el-button :disabled="!scope.row.siteUrl" @click="handleClick(scope.row, 'siteUrl')" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="统计结果">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row, 'statistics1')" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" label="校验配置">
            <template slot-scope="scope">
              <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button> -->
              <el-popover
                placement="right"
                width="300"
                popper-class="caoz"
                trigger="click">
                <span v-for="(item, index) in scope.row.checkConfigs" :key="index" v-show="item.status==1">{{item.name}}&nbsp;</span>
                <el-button slot="reference" type="text" size="small">查看</el-button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="rdUserName" label="指派给"></el-table-column>
          <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
          <el-table-column align="center" prop="status" label="状态"></el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-popover
                placement="right"
                width="200"
                popper-class="caoz"
                trigger="click">
                <el-button @click="handleClick(scope.row, 'notice')" type="text" size="small">通知</el-button>
                <!-- <el-button @click="handleClick(scope.row, 'assign')" type="text" size="small">指派</el-button> -->
                <el-dropdown trigger="click" @command="handleCommand">
                  <span class="el-dropdown-link">
                    <el-button type="text" size="small">指派</el-button>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <div v-for="(item, index) in memberOptions" :key="index">
                      <li class="liTitle">{{item.roleName}}</li>
                      <el-dropdown-item v-for="(item1, index1) in item.roleList" :key="index1" :command="{memberId:item1.memberId,row:scope.row}">{{item1.memberName}}</el-dropdown-item>
                    </div>
                    <!-- <el-dropdown-item :command="{memberId:item.memberId,row:scope.row}">{{item.memberName}}</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button @click="handleClick(scope.row, 'view')" type="text" size="small">查看</el-button>
                <el-button @click="handleClick(scope.row, 'edit')" type="text" size="small">编辑</el-button>
                <el-button @click="handleClick(scope.row, 'log')" type="text" size="small">日志</el-button>
                <el-button @click="handleClick(scope.row, 'statistics')" type="text" size="small">统计</el-button>
                <el-button slot="reference">操作</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
        <Pagination v-if="showPag" :initObj="initObj" @onChangePageData="handleChangePageData" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { Getlist, GetmagList, GetstatusList, GetdataSourceList, PostNotice, GetMemberList, PostAssignTask, GetArticleStatistic } from '@/api/collection'
import Pagination from '@/components/Pagination'
export default {
  name: 'Analysis',
  components: {
    Pagination
  },
  computed: {
    ...mapGetters({
      handleLoading: 'handleLoading',
      recentFiveYear: 'recentFiveYear',
      recentThreeYear: 'recentThreeYear',
      yearOptions: 'yearOptions'
    })
  },
  watch: {
    
  },
  data() {
    return {
      magId: '',
      statusId: '',
      dataSourceId: '',
      memberId: '',
      member: '',
      showPag: false,
      initObj: {size: 10,page:1,total:0},
      tableData: [],
      magOptions: [], //期刊塞选
      statusOptions: [], // 状态塞选
      sourceOptions: [], // 数据源塞选
      memberOptions: [] // 同角色人员
    }
  },
  mounted() {
    this.getmagList()
    this.getstatusList()
    this.getdataSourceList()
    this.getMemberList()
    this.getlist()
  },
  methods: {
    // 塞选事件
    selection(){
      console.log(this.magId, 'magId')
      console.log(this.statusId, 'statusId')
      console.log(this.dataSourceId, 'dataSourceId')
      this.getlist()
    },
    // 采集任务平台-期刊列表
    getmagList(){
      let params = {}
      GetmagList(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          data.unshift({
            magName: '请选择',
            magId: ''
          })
          this.magOptions = data
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 采集任务平台-状态列表
    getstatusList(){
      let params = {}
      GetstatusList(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          data.unshift({
            course: '请选择',
            id: ''
          })
          this.statusOptions = data
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 采集任务平台-获取指派人列表
    getMemberList(){
      let params = {}
      GetMemberList(params).then((res) => {
        let {data, status} = res.data
        // 0:普通  1:超级管理员 2:管理员  3:销售  4:设计 5:服务 6:采集 7:数据中台 8:资源中心 9:产品（管理）
        // let roleName = ''
        // switch (Number(sessionStorage.getItem('role'))) {
        //   case 5:
        //       roleName = '服务人员'
        //       break;
        //   case 6:
        //       roleName = '采集人员'
        //       break;
        //   case 7:
        //       roleName = '数据中台研发'
        //       break;
        //   case 8:
        //       roleName = '资源中心研发'
        //       break;
        //   case 9:
        //       roleName = '产品人员'
        //       break;
        // }
        // console.log(roleName, 'roleName')
        if(status==0){
            // let cj =  []
            // data.forEach(item => {
            //     if(item.roleName === roleName){
            //         cj = item.roleList
            //     }
            // });
            this.memberOptions = data
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 采集任务平台-数据源列表
    getdataSourceList(){
      let params = {}
      GetdataSourceList(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          data.unshift({
            dataSourceName: '请选择',
            dataSourceId: ''
          })
          this.sourceOptions = data
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 采集任务平台-任务列表
    getlist(pageParams = {}) {
      const { size, page, total } = this.initObj
      const params = {
        magId: this.magId,
        statusId: this.statusId,
        dataSourceId: this.dataSourceId,
        memberId: this.memberId,
        page: page - 1,
        size: size
      }
      Getlist(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          data.content.forEach(item=>{
            item.checkConfigs = JSON.parse(item.checkConfig)
          })
          this.tableData = data.content          
          this.initObj.total = data.totalElements
          this.initObj = Object.assign({}, this.initObj)
          this.showPag = true
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 页码改变了之后的操作
    handleChangePageData (pageObj) {
      console.log(pageObj)
       this.initObj.page = pageObj.page
       this.initObj.size = pageObj.size
      this.getlist()
    },
    // 新建任务
    add(){
      this.$router.push('/add').catch((err) => {err})
    },
    // 操作事件
    handleClick(row, type){
      console.log(row, 'row')
      console.log(type, 'type')
      if(type === 'edit'){
        this.$router.push({ path: '/edit', query: { id: row.id } }) // 跳转地址
      } else if(type === 'log'){
        this.$router.push({ path: '/log', query: { id: row.id } }) // 跳转地址
      } else if(type === 'statistics1'){
        this.$router.push({ path: '/statistics', query: { id: row.id } }) // 跳转地址
      } else if(type === 'statistics') {
        let params ={
          id:row.id
        }
        GetArticleStatistic(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
                this.$message.success('开始统计')
            } else{
                this.$message.error(res.data.message)
            }
        })
        // this.$router.push({ path: '/statistics', query: { id: row.id } }) // 跳转地址
      } else if(type === 'view'){
        this.$router.push({ path: '/view', query: { id: row.id } }) // 跳转地址
      } else if(type === 'taskUrl'){
        if(row.taskUrl!==''&&row.taskUrl!==null&&row.taskUrl!==undefined){
          window.open(row.taskUrl)
        }else{
          this.$message.error('工单地址为空')
        }
      } else if(type === 'siteUrl'){
        if(row.siteUrl!==''&&row.siteUrl!==null&&row.siteUrl!==undefined){
         window.open(row.siteUrl)
        }else{
          this.$message.error('网站地址为空')
        }
      } else if( type === 'notice'){
        let params = {
          id: Number(row.id)
        }
        PostNotice(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
                this.$message.success('通知成功')
            } else{
                this.$message.error(res.data.message)
            }
        })
      }
    },
    handleCommand(command){
      console.log(command)
      let params = {
          id: Number(command.row.id),
          member: command.memberId
        }
      PostAssignTask(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
              this.$message.success('指派成功')
          } else{
              this.$message.error(res.data.message)
          }
      })
    },
    tableRowClassName({ row, rowIndex }){
      console.log(row)
      console.log(rowIndex)
      if (row.status === '待采集') {
          return 'colorY';
      }else if(row.status === '预警'){
        return 'colorR';
      }else{
        return ''
      }
    }
  }
}
</script>
<style lang="scss">
  .el-table th > .cell{
    color:#1b1c1f;
  }
  .el-button--text{
    margin: 0 !important;
    margin-right: 10px!important;
    top: 0;
    text-indent: initial;
  }
  .caoz{
    display: flex;
    flex-wrap: wrap;
  }
  .colorY{
    // background-color: #fcf88b !important;
    background-color: rgba(252, 248, 139, 0.6) !important;
    color: #000 !important;
  }
  .colorR{
    background-color: rgba(251, 134, 125, 0.6) !important;
    color: #000 !important;
  }
  .liTitle{
    text-align: center;
    font-size: 12px;
    color: #909399;
    line-height: 30px;
  }
</style>
<style scoped lang="scss">
  .w1{
    width: 100%;
  }
  .center_1_select{
    li{
      float: left;
      margin-bottom: 20px;
      margin-right: 20px;
      span{
        margin-right: 10px;
      }
    }
  }
</style>
