<!-- rangeSetting -->
<template>
  <div class="w center_all rangeSetting">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>内容聚合</li>
        <li>-></li>
        <li>聚合范围设置</li>
      </ul>
      <div class="rangeSetting-content">
        <div class="select-options">
          <div class="YZ select">
            引证期刊 <span>时间：</span>
            <el-select v-model="YZTimeValue" placeholder="请选择">
              <el-option v-for="item in YZTimeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <span>排行：</span>
            <el-select v-model="YZSortValue" placeholder="请选择">
              <el-option v-for="item in YZSortOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
          <div class="YY select">
            引用期刊 <span>时间：</span>
            <el-select v-model="YZTimeValue" placeholder="请选择">
              <el-option v-for="item in YZTimeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <span>排行：</span>
            <el-select v-model="YYSortValue" placeholder="请选择">
              <el-option v-for="item in YYSortOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
        <div class="list">
          <div class="list-item" v-for="item in rangeList" :key="item">
            {{ item }}
          </div>
        </div>
        <el-button class="range-sure" @click="setRange">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { GetTextContent, GetTextRange, SetTextRule } from '@/api/aggregation'
import { mapGetters } from 'vuex'

export default {
  name: 'rangeSetting',
  data() {
    return {
      YZTimeValue: 5,
      YZTimeOptions: [
        {
          value: 5,
          label: '近5年'
        },
        {
          value: 3,
          label: '近3年'
        }
      ],
      YZSortValue: 10,
      YZSortOptions: [
        {
          value: 20,
          label: '前20'
        },
        {
          value: 10,
          label: '前10'
        },
        {
          value: 5,
          label: '前5'
        }
      ],
      YYTimeValue: 5,
      YYTimeOptions: [
        {
          value: 5,
          label: '近5年'
        },
        {
          value: 3,
          label: '近3年'
        }
      ],
      YYSortValue: 10,
      YYSortOptions: [
        {
          value: 20,
          label: '前20'
        },
        {
          value: 10,
          label: '前10'
        },
        {
          value: 5,
          label: '前5'
        }
      ],
      rangeList: []
    }
  },
  props: {},
  components: {},
  computed: {
    ...mapGetters({
      magId: 'magId'
    })
  },
  watch: {
    magId() {
      this.getRangeSetting()
    },
    YZTimeValue() {
      this.getRangeList()
    },
    YZSortValue() {
      this.getRangeList()
    },
    YYTimeValue() {
      this.getRangeList()
    },
    YYSortValue() {
      this.getRangeList()
    }
  },
  mounted() {
    this.getRangeSetting()
  },
  methods: {
    getRangeSetting() {
      const params = {
        magId: this.magId
      }
      GetTextRange(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          if (!data) {
            this.getRangeList()
          } else {
            this.YZTimeValue = data.ctiTotal
            this.YZSortValue = data.ctiSize
            this.YYTimeValue = data.refTotal
            this.YYSortValue = data.refSize
          }
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    getRangeList() {
      const params = {
        magId: this.magId,
        ctiTotal: this.YZTimeValue,
        ctiSize: this.YZSortValue,
        refTotal: this.YYTimeValue,
        refSize: this.YYSortValue
      }
      GetTextContent(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          this.rangeList = data
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    setRange() {
      const params = {
        magId: this.magId,
        ctiTotal: this.YZTimeValue,
        ctiSize: this.YZSortValue,
        refTotal: this.YYTimeValue,
        refSize: this.YYSortValue
      }
      SetTextRule(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { message } = res.data
          this.$message.success(message)
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    }
  }
}
</script>
<style lang="scss">
.rangeSetting {
  .el-select {
    width: 100px;
  }
}
</style>

<style lang="scss" scoped>
.rangeSetting {
  .center_2 {
    min-height: 300px;
    .rangeSetting-content {
      .select-options {
        display: flex;
        .select {
          span {
            margin: 0 10px 0 15px;
          }
        }
        .YY {
          margin-left: 30px;
        }
      }
      .list {
        margin-top: 30px;
        text-align: left;
        .list-item {
          display: inline-block;
          width: 20%;
        }
      }
      .range-sure {
        margin-top: 30px;
      }
    }
  }
}
</style>
