<template>
    <div class="sales salesHeader-box">
        <div class="w center_all">
            <div class="center_1 title">{{isShow?'编辑':'新建'}}</div>
            <div class="center_1 posion">
                <el-form  ref="form" :rules="rules" :model="form" label-width="120px" size="mini" style="text-align: left;">
                    <el-form-item label="期刊名称" prop="magName">
                        <el-autocomplete
                            v-model="form.magName"
                            :fetch-suggestions="querySearchAsync"
                            placeholder="请输入内容"
                            style="width:100%;"
                        ></el-autocomplete>
                    </el-form-item>
                    <el-form-item label="工单地址" prop="taskUrl">
                        <el-input v-model="form.taskUrl"></el-input>
                    </el-form-item>
                    <el-form-item label="网站地址" prop="siteUrl">
                        <el-input v-model="form.siteUrl"></el-input>
                    </el-form-item>
                    <div style="display:flex;">
                        <el-form-item label="数据源" style="width:20%;" prop="dataSourceId">
                            <el-select v-model="form.dataSourceId" placeholder="请选择">
                                <el-option v-for="item in sourceOptions"
                                    :key="item.dataSourceId"
                                    :label="item.dataSourceName"
                                    :value="item.dataSourceId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="指派给" style="width:20%;margin-left: 10%;" prop="rdUserId">
                            <!-- <el-select v-model="form.rdUserId" placeholder="请选择">
                                <el-option v-for="item in memberOptions"
                                    :key="item.memberId"
                                    :label="item.memberName"
                                    :value="item.memberId">
                                </el-option>
                            </el-select> -->
                            <el-select v-model="form.rdUserId" placeholder="请选择">
                                <el-option-group
                                v-for="group in memberOptions"
                                :key="group.roleName"
                                :label="group.roleName">
                                <el-option
                                    v-for="item in group.roleList"
                                    :key="item.memberId"
                                    :label="item.memberName"
                                    :value="item.memberId">
                                </el-option>
                                </el-option-group>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="创建人" v-if="isShow" style="width:20%;margin-left: 10%;" prop="createUserName">
                            <div>{{form.createUserName}}</div>
                        </el-form-item>
                    </div>
                    <el-form-item label="校验配置">
                        <el-checkbox-group v-model="form.checkConfig" v-if="checkData.length>0">
                            <el-checkbox v-for="item in checkData" :key="item.field" :label="item.name"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="出版周期" v-if="isShow">
                        <!-- <el-input v-model="form.publishPeriod"></el-input> -->
                        <el-select v-model="form.publishPeriod" placeholder="请选择">
                            <el-option v-for="(item, index) in periodList"
                                :key="index"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="知网采集周期" v-if="isShow&&form.dataSourceId=='0'">
                        <el-input v-model="form.taskTrigger"></el-input>
                    </el-form-item>
                    <el-form-item label="上次采集时间" v-if="isShow&&form.dataSourceId=='0'">
                        <el-input v-model="form.lastCrawlTime"></el-input>
                    </el-form-item>
                    <div style="display:flex;">
                        <el-form-item label="优先级">
                            <el-input v-model="form.priority"></el-input>
                        </el-form-item>
                        <el-form-item label="强制采集" style="text-align: left;">
                            <el-switch v-model="form.forceRepeatFlag"></el-switch>
                        </el-form-item>
                    </div>
                    <el-form-item label="预计完成时间" style="text-align: left;" v-if="isShow&&role==6">
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择日期" v-model="form.planCompletedTime" style="width: 100%;"></el-date-picker>
                        </el-col>
                    </el-form-item>
                    <div style="display:flex;">
                        <el-form-item label="状态" style="text-align: left; width:27%" v-if="isShow">
                            <el-select v-model="form.statusId" placeholder="请选择">
                                <el-option v-for="item in statusOptions"
                                    :key="item.id"
                                    :label="item.course"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="预测机制" style="text-align: left;">
                            <el-input v-model="form.predictedConfig"></el-input>
                        </el-form-item>
                    </div>
                    <el-form-item style="width:100%;text-align:center" label-width="0">
                        <el-button type="primary" @click="submitForm('form')">{{isShow?'编辑':'新建'}}</el-button>
                        <el-button @click="cancellation()">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GetCheckConfig, GetdataSourceList, GetMemberList, GetQueryMag, PostEdit, GetInfo, GetStatusList, GetPublishPeriodList } from '@/api/collection'
import Pagination from '@/components/Pagination'
export default {
  name: 'Analysis',
  components: {
    Pagination
  },
  computed: {
    ...mapGetters({
      handleLoading: 'handleLoading',
      recentFiveYear: 'recentFiveYear',
      recentThreeYear: 'recentThreeYear',
      yearOptions: 'yearOptions'
    })
  },
  watch: {
    
  },
  data() {
    let validateWebSite=(rule, value, callback)=>{
        //java规则——((http|ftp|https):\\/\\/[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&:/~\\+#]*[\\w\\-\\@?^=%&/~\\+#])?)
        let reg = new RegExp(/((http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?)/)//正则校验                 
        if(value){
                if(!reg.test(value)){
                callback(new Error('请输入正确的网址'))
            }else{
                callback();
            }
        }else{
            callback();
        }        
    }
    return {
        role: '',
        checkData:[], // 校验配置
        sourceOptions:[], // 数据源
        memberOptions:[], // 指派人
        statusOptions:[], // 状态列表
        periodList:[], // 出版周期列表
        form: {
          magName: '',
          taskUrl: '',
          siteUrl: '',
          dataSourceId: '',
          rdUserId: '',
          publishPeriod: '',
          lastCrawlTime: '',
          priority: '',
          forceRepeatFlag: true,
          planCompletedTime: '',
          statusId: '',
          predictedConfig: '',
          createUserName: '',
          checkConfig: []
        },
        rules: {
          magName: [
            { required: true, message: '请输入刊物名称', trigger: 'blur' }
          ],
          taskUrl: [
            { required: true, message: '请输入工单地址', trigger: 'blur' },
            { validator: validateWebSite, trigger: 'blur' }
          ],
          siteUrl: [
            { required: true, message: '请输入网站地址', trigger: 'blur' },
            { validator: validateWebSite, trigger: 'blur' }
          ],
          dataSourceId: [
            { required: true, message: '请选择数据源', trigger: 'change' }
          ],
          rdUserId: [
            { required: true, message: '请选择采集人员', trigger: 'change' }
          ]
        },
        restaurants: [], // 搜索结果
        isShow: false // 是否显示， 新建不显示/编辑显示
    }
  },
  created(){
    this.role = sessionStorage.getItem('role')
    if(this.$route.path === '/add'){
        this.isShow = false
    }else if(this.$route.path === '/edit'){
        this.getStatusList()
        this.getInfo()
        this.isShow = true
    }
    this.getMemberList()
    this.getdataSourceList()
    this.getCheckConfig()
    this.getPublishPeriodList()
  },
  mounted() {
  },
  methods: {
    // 采集任务平台-出版周期列表
    getPublishPeriodList(){
        let params = {}
        GetPublishPeriodList(params).then((res) => {
            let {data, status} = res.data
            if(status==0){
                this.periodList = data
            } else {
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
    },
    // 采集任务平台-状态列表
    getStatusList(){
        let params = {}
        GetStatusList(params).then((res) => {
            let {data, status} = res.data
            if(status==0){
                this.statusOptions = data
            } else {
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
    },
    // 采集任务平台-任务详情
    getInfo(){
      let params = {id:this.$route.query.id}
      GetInfo(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
            console.log(data)
            let checkConfigData = []
            let checkConfig = JSON.parse(data.checkConfig) || []
            if(checkConfig.length>0){
                checkConfig.forEach(item=>{
                    if(item.status == 1){
                        checkConfigData.push(item.name)
                    }                
                })
            }
            
            this.form = {
                magName: data.magName,
                magId: data.magId,
                taskUrl: data.taskUrl,
                siteUrl: data.siteUrl,
                dataSourceId: data.dataSourceId,
                rdUserId: data.rdUserId,
                publishPeriod: data.publishPeriod,
                lastCrawlTime: data.lastCrawlTime,
                priority: data.priority,
                forceRepeatFlag: data.forceRepeatFlag==1?true:false,
                planCompletedTime: data.planCompletedTime,
                status: data.status,
                statusId: data.statusId,
                predictedConfig: data.predictedConfig,
                checkConfig: checkConfigData,
                createUserName: data.createUserName,
                latestIssue: data.latestIssue, //当前最新刊期，可编辑，新建时不用传
                predictedIssue:data.predictedIssue, //预测当前刊期，可编辑，新建时不用传
                taskTrigger: data.taskTrigger //采集间隔 非必传
            }
            // this.memberOptions = cj
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 提交表单
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                let params = {}
                let magId = ''
                let dataSourceName = ''
                let memberName = ''
                let status = ''
                let checkConfig = []
                let form = this.form
                for(let i in form){
                    if(i === 'magName'){
                        this.restaurants.forEach(item =>{
                            if(form[i] === item.magName){
                                magId = item.magId
                            }
                        })                        
                    }
                    if(i === 'dataSourceId'){
                        this.sourceOptions.forEach(item =>{
                            if(form[i] === item.dataSourceId){
                                dataSourceName = item.dataSourceName
                            }
                        })
                    }
                    if(i === 'rdUserId'){
                        this.memberOptions.forEach(item =>{
                            item.roleList.forEach(ite=>{
                                if(form[i] === ite.memberId){
                                    memberName = ite.memberName
                                }
                            })
                        })
                    }
                    if(i === 'checkConfig'){
                        this.checkData.forEach(item =>{                        
                            if(form[i].includes(item.name)){
                                checkConfig.push({
                                    field:item.field,
                                    name:item.name,
                                    status: 1
                                })
                            }else{
                                checkConfig.push(item)
                            }
                        })
                    }
                    if(i === 'statusId'){
                        this.statusOptions.forEach(item =>{
                            if(form[i] === item.id){
                                status = item.course
                            }
                        })
                    }
                }
                if(this.$route.path === '/add'){
                    // 新建
                    params = {
                        magId: magId, //期刊ID 必传
                        magName: form.magName, //刊物名称 必传
                        predictedConfig:form.predictedConfig, //预测配置 非必传
                        dataSourceId: form.dataSourceId, //数据源ID 必传
                        dataSource: dataSourceName, //数据源 必传
                        priority: form.priority, //优先级，1为最低，10位最大 非必传
                        forceRepeatFlag:form.forceRepeatFlag?1:0, //强制重采标识，0不强制，1为强制 非必传
                        // taskTrigger:'', //采集间隔 非必传
                        taskUrl:form.taskUrl, //工单地址 非必传
                        siteUrl:form.siteUrl, //网站地址 非必传
                        // status:'', //流程状态 非必传
                        // statusId:'', //流程状态ID 必传
                        rdUserId:form.rdUserId, //指派给人员Id 必传
                        rdUserName:memberName, //指派给人员姓名 必传
                        checkConfig: JSON.stringify(checkConfig), //校验配置 必传
                    }
                }else if(this.$route.path === '/edit'){
                    // 编辑
                    params = {
                        id: Number(this.$route.query.id), // 任务id，新建时不要传，编辑是必传
                        magId: form.magId, //期刊ID 必传
                        magName: form.magName, //刊物名称 必传
                        publishPeriod: form.publishPeriod, //出版周期，可编辑，新建时不用传
                        latestIssue: form.latestIssue, //当前最新刊期，可编辑，新建时不用传
                        predictedIssue: form.predictedIssue, //预测当前刊期，可编辑，新建时不用传
                        predictedConfig: form.predictedConfig, //预测配置 非必传
                        dataSourceId: form.dataSourceId, //数据源ID 必传
                        dataSource: dataSourceName, //数据源 必传
                        lastCrawlTime: form.lastCrawlTime, //上次采集时间 非必传
                        priority: form.priority, //优先级，1为最低，10位最大 非必传
                        forceRepeatFlag: form.forceRepeatFlag?1:0, //强制重采标识，0不强制，1为强制 非必传
                        taskTrigger: form.taskTrigger, //采集间隔 非必传
                        taskUrl:form.taskUrl, //工单地址 非必传
                        siteUrl:form.siteUrl, //网站地址 非必传
                        status: status, //流程状态 非必传
                        statusId: form.statusId, //流程状态ID 必传
                        rdUserId: form.rdUserId, //指派给人员Id 必传
                        rdUserName: memberName, //指派给人员姓名 必传
                        checkConfig: JSON.stringify(checkConfig), //校验配置 必传
                        planCompletedTime: form.planCompletedTime //计划完成时间 非必传
                    }
                }
                PostEdit(params).then((res) => {
                    if (res && res.data && res.data.status === 0) {
                        if(this.$route.path === '/add'){
                            this.$message.success('新建成功')
                        }else if(this.$route.path === '/edit'){
                            this.$message.success('编辑成功')
                        }                       
                        setTimeout(()=>{
                            this.$router.push('/collection').catch((err) => {err})
                        }, 800)
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            } else {
                console.log('error submit!!');
            return false;
            }
        });
    },
    // 重置表单
    resetForm(formName) {
        this.$refs[formName].resetFields();
    },
    // 取消
    cancellation(){
       this.$router.push({ path: '/collection', query: { } }) // 跳转地址
    },
    // 采集任务平台-获取指派人列表-用采集人员
    getMemberList(){
      let params = {}
      GetMemberList(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
            // let cj =  []
            // data.forEach(item => {
            //     if(item.roleName === '采集人员'){
            //         cj = item.roleList
            //     }
            // });
            this.memberOptions = data
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 采集任务平台-数据源列表
    getdataSourceList(){
      let params = {}
      GetdataSourceList(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          this.sourceOptions = data
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 采集任务平台-校验配置列表
    getCheckConfig(pageParams = {}) {
      const params = {}
      GetCheckConfig(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          this.checkData = data          
          console.log(this.checkData)
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 远程搜索
    querySearchAsync(queryString, cb) {
        console.log(queryString)
        console.log(cb)
        let params = {
            keyWords: queryString
        }
        // var restaurants = this.restaurants;
        var results = [] // queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
        // 采集任务平台-期刊联想查询
        GetQueryMag(params).then((res) => {
            let {data, status} = res.data
            if(status==0){
                data.forEach(item=>{
                    results.push({
                        value:item.magName,
                        magName:item.magName,
                        magId: item.magId,
                    })
                })
                this.restaurants = results
                cb(results)
            }else{
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
        // clearTimeout(this.timeout);
        // this.timeout = setTimeout(() => {
        //     cb(results);
        // }, 3000 * Math.random());
    }    
  }
}
</script>
<style scoped lang="scss">
  .w1{
    width: 100%;
  }
  .center_1{
    .el-form-item{
        width: 50%;
    }
    .el-checkbox-group{
        display: flex;
        flex-wrap: wrap;
        width: 800px;
        .el-checkbox{
            width: 100px;
            text-align: left;
        }
    }
  }
  .title{
      font-size: 20px;
      font-weight: bold;
  }
</style>
