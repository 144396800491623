import { render, staticRenderFns } from "./index.vue?vue&type=template&id=68af0676&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=68af0676&prod&lang=scss"
import style1 from "./index.vue?vue&type=style&index=1&id=68af0676&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68af0676",
  null
  
)

export default component.exports