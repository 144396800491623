<template>
  <div class="sales salesHeader-box">
    <div class="header-box w">
       <el-button class="position" plain @click="back()">返回</el-button>
       <p class="info-title">{{ infoData.magName }}</p>
       <ul>
        <li v-for="(item, index) in infoKeys" :key="index">
            <label>{{ item.name }}：</label>
            <span :class="{'y-color': item.key == 'statisticsTime'}">{{ infoData[item.key] ? infoData[item.key] : '--' }}</span>
        </li>
       </ul>
       <el-button type="primary" class="refresh" @click="getArticleStatistic">刷新统计</el-button>
    </div>
    <div class="center_all">
        <el-row>
            <el-col :span="6">
                <el-collapse v-model="activeName">
                    <el-collapse-item title="年份筛选" name="1">
                        <el-menu default-active="1-1" class="el-menu-vertical-demo">
                            <el-submenu :index="index + 1 + ''" v-for="(item, index) in yearData" :key="index">
                                <span slot="title" class="title">
                                    <span>{{ item.year }}</span>
                                    <span>
                                        <span v-for="(elp, eldp) in tools" :key="eldp" :class="elp.key"  class="siteCount1" >
                                             <span v-if="Number(item[elp.key]) > 0">({{ item[elp.key] }})</span>
                                        </span>
                                    </span>
                                </span>
                                <el-menu-item :index="`${index + 1 + ''}-${index1 + 1 + ''}`" v-for="(item1, index1) in item.issueList" :key="index1" @click="checkMenu(item1)">
                                    <span>第{{ item1.issue }}期</span>
                                    <span>
                                        <span v-for="(elc, eldc) in tools" :key="eldc" :class="elc.key" class="siteCount1">
                                            <span v-if="Number(item1[elc.key]) > 0">({{ item1[elc.key] }})</span>
                                        </span>
                                        <el-button type="primary" size="mini" class="refresh" @click="comparison(item1)">比对</el-button>
                                    </span>
                                </el-menu-item>
                            </el-submenu>
                        </el-menu>
                    </el-collapse-item>
                </el-collapse>
            </el-col>
            <el-col :span="18" class="flex-right">
                <ul class="tools">
                    <li v-for="item in tools" :key="item.key">
                        <span :class="item.key"></span>:{{ item.tool }}
                    </li>
                    <li>
                        <label>筛选条件：</label>
                        <el-select v-model="toolValue" placeholder="全部" @change="checkMenu()">
                            <el-option
                            v-for="item in screenOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </li>
                </ul>
                <ul class="thesis-list" v-if="thesisData && thesisData.length > 0">
                    <li v-for="(item, index) in thesisData" :key="index">
                       <p :title="item.title" @click="toPath(item)">{{ item.title }}</p>
                       <div class="list">
                        <span v-for="(item1, index1) in thesisInfo" :key="index1" class="item">
                            <label>{{ item1.name }}：</label><span :title="item[item1.key]">{{ item[item1.key] ? item[item1.key] : '--'}}</span>
                        </span>
                       </div>
                       <div class="discontent"><label>不满足校验配置：</label>
                        <span v-for="(el, eld) in item.errField" :key="eld" class="errField">{{ el }}</span>
                       </div>
                       <div class="updata-time"><label>论文更新时间：</label><span>{{ item.updateTime }}</span></div>
                    </li>
                </ul>
                <div class="noData" v-else>暂无数据</div>
                <Pagination v-if="showPag && this.initObj.total > 0" :initObj="initObj" @onChangePageData="handleChangePageData" />
            </el-col>
        </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GetInfo, GetArticleStatistic, GetClusterYearIssue, GetArticlePage } from '@/api/collection'
import Pagination from '@/components/Pagination'
export default {
name: 'Statistics',
components: {
    Pagination
},
computed: {
    ...mapGetters({
    handleLoading: 'handleLoading',
    recentFiveYear: 'recentFiveYear',
    recentThreeYear: 'recentThreeYear',
    yearOptions: 'yearOptions'
    })
},
watch: {
    
},
data() {
    return {
        initObj: {size: 10,page:1,total:0},
        infoData: '',
        infoKeys: [
            {
                name: '数据源',
                key: 'dataSource'
            },
            {
                name: '出版周期',
                key: 'publishPeriod'
            },
            {
                name: '网站地址',
                key: 'siteUrl'
            },
            {
                name: '上次统计时间',
                key: 'statisticsTime'
            }
        ],
        yearData: [],
        checkData: [],
        tools: [
            {tool: '网站论文数量', key: 'siteCount'},
            {tool: 'OSS论文数量', key: 'ossCount'},
            {tool: '数据中台论文数量', key: 'dataCenterCount'},
            {tool: '资源中心论文数量', key: 'resourceCenterCount'},            
            {tool: '不满足校验的论文数量', key: 'errCount'},
        ],
        screenOptions: [
            {label: '全部', value: 0},
            {label: '不满足校验条件', value: 1}
        ],
        thesisData: [],
        thesisInfo: [
            {
                name: '作者',
                key: 'authors'
            },
            {
                name: '英文作者',
                key: 'authorsEn'
            },
            {
                name: '机构',
                key: 'institutions'
            },
            {
                name: '关键词',
                key: 'keywords'
            }
        ],
        toolValue: 0,
        activeName: '1',
        showPag: false
    }
},
created(){
    this.getInfo()
},
mounted() {
},
methods: {
    back(){
        this.$router.push({ path: '/collection', query: { } }) // 跳转地址
    },
    // 跳转详情
    toPath(val) {
       window.open(val.url)
    },
    comparison(item) {
        this.$router.push({ 
            path: '/comparison', 
            query: {
                id: this.$route.query.id,
                dataSourceId: this.infoData.dataSourceId,
                issue: item.issue,
                year: item.year,
                magId: this.infoData.magId
            }
        }) // 跳转地址
    },
    // 选中期数，获取论文列表
    checkMenu(item1) {
        console.log(item1, 'item1')
        if (item1 && item1 !== 'init') {
            this.checkData = item1
        } else if (item1 === 'init') {
            this.checkData = []
        }
        const { size, page } = this.initObj
        const params = {
            dataSourceId: this.infoData.dataSourceId,
            magId: this.infoData.magId,
            year: this.checkData.year,
            issue: this.checkData.issue,
            type: this.toolValue,
            page: page - 1,
            size: size
        }
        GetArticlePage(params).then((res) => {
            let { data, status } = res.data
            if (status==0) {
                this.thesisData = data.content
                this.initObj.total = data.totalElements
                this.initObj = Object.assign({}, this.initObj)
                this.showPag = true
            } else {
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
    },
    // 页码改变了之后的操作
    handleChangePageData (pageObj) {
       this.initObj.page = pageObj.page
       this.initObj.size = pageObj.size
       this.checkMenu()
    },
    // 采集任务平台-数据源列表
    getInfo(){
        const params = {
            id: this.$route.query.id
        }
        GetInfo(params).then((res) => {
            let { data, status } = res.data
            if (status==0) {
                this.infoData = data
                this.getclusterYearIssue(data.magId)
            } else {
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
    },
    getArticleStatistic() {
        const params = {
            id: this.$route.query.id
        }
        GetArticleStatistic(params).then((res) => {
            let { status } = res.data
            if (status==0) {
                this.$message.success('刷新成功')
            } else {
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
    },
    getclusterYearIssue(magId) {
        const params = {
            dataSourceId: this.infoData.dataSourceId,
            magId: magId
        }
        GetClusterYearIssue(params).then((res) => {
            let { data, status } = res.data
            if (status==0) {
                if (data) {
                    this.yearData = data
                    if(data[0].issueList) {
                        this.checkMenu(data[0].issueList[0])
                    } else {
                        this.checkMenu('init')
                    }
                } else {
                    this.checkMenu('init')
                }
            } else {
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
    }
  }
}
</script>
<style scoped lang="scss">
.center_all {
    width: 1200px;
    padding-top: 10px;
    margin: 0 auto;
}
.position {
    position: absolute;
    top: 20px;
    left: 30px;
}
.header-box {
    position: relative;
    width: 1500px;
    background: #eee;
    border: 1px solid #ccc;
    text-align: left;
    padding: 20px 150px 32px;
    box-sizing: border-box;
    .info-title {
        font-size: 18px;
        color: #333;
        font-weight: 700;
        line-height: 32px;
    }
    ul {
        font-size: 16px;
        line-height: 28px;
    }
    .refresh{
        position: absolute;
        bottom: 0;
        left: 450px;
    }
    .y-color {
        color: #E99D42;
    }
}
.flex-right {
    padding-left: 10px;
    .tools {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 2px solid #ccc;
        padding-bottom: 5px;
        li {
            width: calc(100% / 3);
            text-align: left;
            height: 30px;
            display: flex;
            align-items: center;
            font-size: 14px;
            span {
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                border: 1px solid;
                margin-right: 5px;
            }
        }
    }
    .thesis-list{
        text-align: left;
        li {
            padding: 15px 0;
            border-bottom:  2px solid #ccc;
            p {
                font-size: 16px;
                font-weight: 700;
                line-height: 28px;
                margin-bottom: 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                cursor: pointer;
            }
            .list {
                width: 60%;
                display: flex;
                flex-wrap: wrap;
                .item {
                    width: 50%;
                    font-size: 14px;
                    color: #333;
                    line-height: 24px;
                    margin-bottom: 10px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
            .discontent {
                height: 39px;
                line-height: 39px;
                margin-bottom: 10px;
               .errField {
                 padding: 10px;
                 font-size: 16px;
                 color: #fff;
                 font-weight: 700;
                 border: 1px solid #ccc;
                 background: #E99D42;
                 margin-right: 10px;
               }
            }
            .updata-time {
                width: 50%;
                font-size: 14px;
                color: #333;
                line-height: 24px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
.noData {
    min-height: calc(100vh - 100px - 186px - 77px);
    line-height: calc(100vh - 100px - 186px - 77px);
    font-size: 16px;
}
/deep/ .el-button--mini {
    padding: 3px 10px;
}
/deep/ .el-collapse-item {
    .el-collapse-item__wrap {
        border-right: 2px solid #ccc;
    }
    .el-collapse-item__header {
        border-radius: 5px;
        border:1px solid #ccc;
        background: #eee;
        padding-left: 10px;
        font-size: 16px;
    }
    .el-collapse-item__content {
        padding-bottom: 0px;
    } 
    .el-collapse-item__arrow{
        font-size: 16px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
    .el-collapse-item__arrow.is-active {
        -webkit-transform: rotate(90deg) !important;
        transform: rotate(90deg) !important;
    }
}
/deep/ .el-menu{
    text-align: left;
    border-right:none ;
        .el-submenu {
            width: 100%;
            .el-submenu__title {
                height: 40px;
                line-height: 40px;
                .title {
                    padding-right: 55px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .el-icon-arrow-down:before{
                    content: "\e6df" !important;
                }
                .el-submenu__icon-arrow {
                    font-size: 14px;
                    transform: rotateZ(0deg);
                }
            }
            .el-menu-item {
              position: relative;
              background: #eee;
              height: 40px;
              line-height: 40px;
              padding-right: 5px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &:hover{
                border-radius: 0;
                background: #ecf5ff;
              }
              .refresh {
                margin-left: 5px;
              }
            }
            .el-menu-item.is-active {
                background: #ecf5ff;
            }
        }
        .el-submenu.is-opened {
            .el-submenu__icon-arrow {
                transform: rotate(180deg) !important;
            }
        }
    }
    .siteCount {
        color: blue;
        border-color: blue;
        background: blue;
    }
    .dataCenterCount {
        color: black;
        border-color: black;
        background: black;
    }
    .ossCount {
        color: gray;
        border-color: gray;
        background: gray;
    }
    .resourceCenterCount {
        color: #54BCBD;
        border-color: #54BCBD;
        background: #54BCBD;
    }
    .errCount {
        color: #E99D42;
        border-color:#E99D42;
        background: #E99D42;
    }
    .siteCount1{
        background: transparent;
    }
</style>
  