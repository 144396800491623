<template>
  <div class="sales salesHeader-box">
    <div class="w center_all">
        <el-button class="position" plain @click="back()">返回</el-button>
        <div class="center_1 title">日志记录</div>
        <div class="center_1 posion">
            <el-table :data="tableData" border style="width: 100%" :header-cell-style="{background: '#f3f4f8;'}">
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column align="center" prop="operationContent" label="操作内容"></el-table-column>
            <el-table-column align="center" prop="operationTime" label="操作时间"></el-table-column>
            <el-table-column align="center" prop="operatorName" label="操作人"></el-table-column>
        </el-table>
        <Pagination v-if="showPag" :initObj="initObj" @onChangePageData="handleChangePageData" />
        </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GetOperationLogs } from '@/api/collection'
import Pagination from '@/components/Pagination'
export default {
  name: 'Analysis',
  components: {
    Pagination
  },
  computed: {
    ...mapGetters({
      handleLoading: 'handleLoading',
      recentFiveYear: 'recentFiveYear',
      recentThreeYear: 'recentThreeYear',
      yearOptions: 'yearOptions'
    })
  },
  watch: {
    
  },
  data() {
    return {
        initObj: {size: 10,page:1,total:0},
        tableData: [],
        showPag: false
    }
  },
  created(){
    this.getOperationLogs()
  },
  mounted() {
  },
  methods: {
    back(){
        this.$router.push({ path: '/collection', query: { } }) // 跳转地址
    },
    // 采集任务平台-数据源列表
    getOperationLogs(){
      const { size, page, total } = this.initObj
      const params = {
        id: this.$route.query.id,
        page: page - 1,
        size: size
      }
      GetOperationLogs(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          this.tableData = data.content          
          this.initObj.total = data.totalElements
          this.initObj = Object.assign({}, this.initObj)
          this.showPag = true
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 页码改变了之后的操作
    handleChangePageData (pageObj) {
       this.initObj.page = pageObj.page
       this.initObj.size = pageObj.size
      this.getOperationLogs()
    },
  }
}
</script>
<style scoped lang="scss">
  .w{
    position: relative;
  }
  .center_1{
    .el-form-item{
        width: 50%;
    }
    .el-checkbox-group{
        display: flex;
        flex-wrap: wrap;
        width: 800px;
        .el-checkbox{
            width: 100px;
            text-align: left;
        }
    }
  }
  .title{
      font-size: 20px;
      font-weight: bold;
  }
  .position{
      position: absolute;
      top: 30px;
      left: 30px;
  }
</style>
