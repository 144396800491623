<template>
  <div class="w center_all" v-loading="handleLoading">
    <div class="center_1 posion">
      <Overview @onAnchorScroll="handleAnchorScroll" />
      <!-- 引证统计 -->
      <div>
        <h2 class="center_title">
          期刊聚类引证趋势
          <div class="fr btn">
            <el-radio-group v-model="citationTimeValue">
              <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
            </el-radio-group>
          </div>
        </h2>
        <SimpleTable
          ref="citationTable"
          :is-highlight="true"
          :popContent="popContent"
          :tableData="citationTableData"
          :columnData="citationTimeValue === 3 ? citationColumnThree : citationColumn"
          :initObj="citationPageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'citation')
            }
          "
          @onChangePageData="
            (pageObj) => {
              getCitationData(pageObj)
            }
          "
          @onClickTitle="
            (item) => {
              handleClusterDetail(item, 'citation')
            }
          "
          @onClickPopIcon="
            (item) => {
              handleSearchClusterList(item)
            }
          "
        />
      </div>
      <!--引用统计  -->
      <div>
        <h2 class="center_title">
          期刊聚类引用趋势
          <div class="fr btn">
            <el-radio-group v-model="quoteTimeValue">
              <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
            </el-radio-group>
          </div>
        </h2>
        <SimpleTable
          ref="quoteTable"
          :is-highlight="true"
          :popContent="popContent"
          :tableData="quoteTableData"
          :columnData="quoteTimeValue === 3 ? quoteColumnThree : quoteColumn"
          :initObj="quotePageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'quote')
            }
          "
          @onChangePageData="
            (pageObj) => {
              getReferenceData(pageObj)
            }
          "
          @onClickTitle="
            (item) => {
              handleClusterDetail(item, 'quote')
            }
          "
          @onClickPopIcon="
            (item) => {
              handleSearchClusterList(item)
            }
          "
        />
      </div>
      <!-- 影响因子 -->
      <div>
        <h2 class="center_title">
          影响因子
          <div class="fr btn">
            <el-button
              type="text"
              icon="el-icon-xz"
              @click="
                () => {
                  handleDownload('influence')
                }
              "
              >下载</el-button
            >
          </div>
        </h2>
        <SimpleTable
          :tableData="influenceTableData"
          :columnData="influenceColumn"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'influence')
            }
          "
          :initObj="influencePageObj"
          @onChangePageData="
            (pageObj) => {
              getInfluenceData(pageObj)
            }
          "
          @onClickTitle="
            (item) => {
              handleClusterDetail(item, 'influence')
            }
          "
        />
      </div>
      <!-- 机构发文统计-->
      <div id="magInstitutionCount">
        <h2 class="center_title">
          机构发文统计
          <div class="fr btn">
            <el-select v-model="institutionTypeValue" placeholder="请选择" style="width:100px;top:-4px;">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            <el-radio-group v-model="institutionTimeValue">
              <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
            </el-radio-group>
            <el-button
              type="text"
              icon="el-icon-xz"
              @click="
                () => {
                  handleDownload('institution')
                }
              "
              >下载</el-button
            >
          </div>
        </h2>
        <SimpleTable
          ref="institutionTable"
          :tableData="institutionTableData"
          :columnData="institutionTimeValue === 3 ? institutionColumnThree : institutionColumn"
          :initObj="institutionPageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'institution')
            }
          "
          @onChangePageData="
            (pageObj) => {
              getInstitutionData(pageObj)
            }
          "
        />
      </div>
      <!-- 引证机构列表统计 -->
      <div id="magArticleCount">
        <h2 class="center_title">
          引证机构列表统计
          <div class="fr btn">
            <el-radio-group v-model="articleTimeValue">
              <el-radio-button v-for="item in timeOptions" :key="item.key" :label="item.key">{{ item.value }}</el-radio-button>
            </el-radio-group>
            <!-- <el-button
              type="text"
              icon="el-icon-xz"
              @click="
                () => {
                  handleDownload('article')
                }
              "
              >下载</el-button
            > -->
          </div>
        </h2>
        <SimpleTable
          ref="articleTable"
          :tableData="citationInstitutionTableData"
          :columnData="articleTimeValue === 3 ? articleColumnThree : articleColumn"
          :initObj="articlePageObj"
          @onChangeSort="
            (prop, order) => {
              handleChangeSort(prop, order, 'article')
            }
          "
          @onChangePageData="
            (pageObj) => {
              citationInstitution(pageObj)
            }
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import Overview from './component/Overview'
import { mapGetters } from 'vuex'
import SimpleTable from '@/components/SimpleTable'
import { GetInfluence, GetInstitution, GetArticle, exportInfluence, exportInstitution, exportArticle, CitationInstitution } from '@/api/analysis'
import { GetCitationCluster, GetReferenceCluster, GetGetMags } from '@/api/cluster'
export default {
  name: 'Analysis',
  components: {
    Overview,
    SimpleTable
  },
  computed: {
    ...mapGetters({
      magId: 'magId',
      handleLoading: 'handleLoading',
      recentFiveYear: 'recentFiveYear',
      recentThreeYear: 'recentThreeYear',
      yearOptions: 'yearOptions'
    })
  },
  watch: {
    magId() {
      // 重新请求所有的表格
      this.getReferenceData()
      this.getCitationData()
      this.getInfluenceData()
      this.getInstitutionData()
      // this.getArticleData()
      this.citationInstitution()
      // 将所有的table排序改变
      this.clearAllTableSort()
    },
    articleTypeValue() {
      // this.getArticleData()
      this.citationInstitution()
      this.$refs.articleTable.clearSort()
    },
    institutionTypeValue() {
      this.getInstitutionData()
      this.$refs.institutionTable.clearSort()
    },
    citationTimeValue(nv) {
      this.citationPageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.getCitationData()
      this.$refs.citationTable.clearSort()
    },
    quoteTimeValue(nv) {
      this.quotePageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.getReferenceData()
      this.$refs.quoteTable.clearSort()
    },
    institutionTimeValue(nv) {
      this.institutionPageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.getInstitutionData()
      this.$refs.institutionTable.clearSort()
    },
    articleTimeValue(nv) {
      this.articlePageObj.sort = nv === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
      this.citationInstitution()
      this.$refs.articleTable.clearSort()
    }
  },
  data() {
    return {
      popContent: '', // pop弹窗展示的数据
      // 引证统计
      // 引证统计表格
      citationTableData: [],
      citationPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      }, // 引证统计分页
      citationTimeValue: 5, // 当前 引证统计 时间 选中项
      citationColumn: [
        {
          title: '引证刊名',
          prop: 'clusterName',
          special: true,
          width: '300px',
          notSortable: true,
          hasPop: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        }
      ], // 引证统计 表格header 内容（近五年）
      citationColumnThree: [
        {
          title: '引证刊名',
          prop: 'clusterName',
          special: true,
          width: '300px',
          notSortable: true,
          hasPop: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        }
      ], // 引证统计 表格header 内容（近三年）
      // 引用统计
      quoteTableData: [],
      quotePageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      quoteTimeValue: 5, // 当前 引用统计 时间 选中项
      quoteColumnThree: [
        {
          title: '引用刊名',
          prop: 'clusterName',
          special: true,
          width: '300px',
          notSortable: true,
          hasPop: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        }
      ], // 引用统计 表格header 内容(近三年)
      quoteColumn: [
        {
          title: '引用刊名',
          prop: 'clusterName',
          special: true,
          width: '300px',
          notSortable: true,
          hasPop: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        }
      ], // 引用统计 表格header 内容(近五年)
      // 影响因子
      influenceTableData: [],
      influencePageObj: {
        page: 1,
        size: 10,
        sort: ''
      },
      radio3: '近3年', // 当前 影响因子 选中项
      influenceColumn: [
        {
          title: '刊物名称',
          prop: 'name',
          special: true,
          width: '300px',
          notSortable: true
        },
        {
          title: '知网',
          prop: 'influence1'
        },
        {
          title: '万方',
          prop: 'influence2'
        },
        {
          title: '百度学术',
          prop: 'influence3'
        }
      ], // 影响因子 表格header 内容
      // 发文机构统计
      // 近几年选项的options
      timeOptions: [
        {
          key: 5,
          value: '近5年'
        },
        {
          key: 3,
          value: '近3年'
        }
      ],
      institutionTimeValue: 5, // 当前 发文机构统计 时间筛选 选中项
      institutionPageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      institutionTableData: [],
      institutionColumn: [
        {
          title: '发文机构',
          prop: 'institutionName',
          special: true,
          width: '190px',
          notSortable: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        },
        {
          title: '平均被引',
          prop: 'referedCount5'
        },
        {
          title: '单篇最高被引',
          width: '150px',
          prop: 'singleReferedCount5'
        }
      ], // 发文机构统计 表格header 内容（近五年）
      institutionColumnThree: [
        {
          title: '发文机构',
          prop: 'institutionName',
          special: true,
          width: '300px',
          notSortable: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        },
        {
          title: '平均被引',
          prop: 'referedCount3'
        },
        {
          title: '单篇最高被引',
          width: '150px',
          prop: 'singleReferedCount3'
        }
      ], // 发文机构统计 表格header 内容（近三年）
      // 发文统计和引证统计的option选项
      options: [
        {
          value: 'mag',
          label: '本刊'
        },
        {
          value: 'domain',
          label: '本领域'
        }
      ],
      institutionTypeValue: 'mag',
      // 论文引证统计
      articlePageObj: {
        page: 1,
        size: 10,
        sort: 'totalCount5,desc'
      },
      articleTableData: [],
      radio5: '近3年', // 当前 论文引证统计 选中项
      // articleColumn: [
      //   {
      //     title: '论文标题',
      //     prop: 'articleName',
      //     special: true,
      //     width: '300px',
      //     notSortable: true
      //   },
      //   {
      //     title: '刊物名称',
      //     prop: 'magName',
      //     notSortable: true
      //   },
      //   {
      //     title: '作者',
      //     prop: 'author',
      //     notSortable: true
      //   },
      //   {
      //     title: '机构',
      //     prop: 'institution',
      //     notSortable: true
      //   },
      //   {
      //     title: '年份',
      //     prop: 'year'
      //   },
      //   {
      //     title: '刊期',
      //     prop: 'issue',
      //     notSortable: true
      //   },
      //   {
      //     title: '引证次数',
      //     prop: 'totalCount'
      //   }
      // ], // 论文引证统计 表格header 内容
      articleTypeValue: 'mag',
      articleTimeValue: 5, // 当前 引证机构列表统计 时间筛选 选中项
      articleColumn: [
        {
          title: '引证机构',
          prop: 'institutionName',
          special: true,
          width: '190px',
          notSortable: true
        },
        ...recentFiveYear,
        {
          title: '合计',
          prop: 'totalCount5'
        }
      ], // 引证机构列表统计 表格header 内容（近五年）
      articleColumnThree: [
        {
          title: '引证机构',
          prop: 'institutionName',
          special: true,
          width: '300px',
          notSortable: true
        },
        ...recentThreeYear,
        {
          title: '合计',
          prop: 'totalCount3'
        }
      ], // 引证机构列表统计 表格header 内容（近三年）
      citationInstitutionTableData: []
    }
  },
  mounted() {
    if (this.magId) {
      this.getReferenceData()
      this.getCitationData()
      this.getInfluenceData()
      this.getInstitutionData()
      // this.getArticleData()
      this.citationInstitution()
    }
  },
  methods: {
    // 点击聚类icon弹出窗口展示列表
    handleSearchClusterList(clusterItem) {
      const { clusterMagIds = '' } = clusterItem
      const params = {
        magIds: clusterMagIds
      }
      GetGetMags(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data = [] } = res.data
          const popStringArr = data.map((item) => {
            return item.name
          })
          this.popContent = popStringArr.join(',')
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 点击聚类列表标题跳转详情页
    handleClusterDetail(clusterItem, type) {
      if (type === 'influence') {
        let searchName = clusterItem.name
        let searchId = clusterItem.id
        if (searchId && searchId !== '') {
          this.$router.push({ path: '/details', query: { searchId, magId: this.magId } }) // 跳转地址
        } else {
          GetMagByName({ magName: searchName }).then((res) => {
            if (res && res.data && res.data.status === 0) {
              const { data } = res.data
              const { id = '' } = data
              if (id !== '') {
                this.$router.push({ path: '/details', query: { searchId: id, magId: this.magId } }) // 跳转地址
              }
            } else {
              const { message = '' } = res.data
              this.$message.error(message)
            }
          })
        }
      } else {
        window.sessionStorage.setItem('chooseClusterObj', JSON.stringify(clusterItem))
        this.$router.push({ path: '/clusterDetails', query: { searchIds: clusterItem.clusterMagIds, type, magId: this.magId } })
      }
    },
    // 处理锚点跳转
    handleAnchorScroll(key) {
      document.querySelector(`#${key}`).scrollIntoView(true)
    },
    // 处理所有下载的函数
    handleDownload(downloadType = '') {
      // if (downloadType === 'citation') {
      //   window.open(`${exportCitation}?magId=${this.magId}&page=${this.citationPageObj.page - 1}&size=${this.citationPageObj.size}&sort=${this.citationPageObj.sort || ''}`, '_self')
      //   return
      // }
      // if (downloadType === 'quote') {
      //   window.open(`${exportReference}?magId=${this.magId}&page=${this.quotePageObj.page - 1}&size=${this.quotePageObj.size}&sort=${this.quotePageObj.sort || ''}`, '_self')
      //   return
      // }
      if (downloadType === 'influence') {
        window.open(`${exportInfluence}?magId=${this.magId}&page=${this.influencePageObj.page - 1}&size=${this.influencePageObj.size}&sort=${this.influencePageObj.sort || ''}`, '_self')
        return
      }
      if (downloadType === 'institution') {
        window.open(
          `${exportInstitution}?type=${this.institutionTypeValue}&magId=${this.magId}&page=${this.institutionPageObj.page - 1}&size=${this.institutionPageObj.size}&sort=${this.institutionPageObj
            .sort || ''}`,
          '_self'
        )
        return
      }
      // if (downloadType === 'article') {
      //   window.open(
      //     `${exportArticle}?magId=${this.magId}&page=${this.articlePageObj.page - 1}&size=${this.articlePageObj.size}&sort=${this.articlePageObj.sort || ''}
      //     `,
      //     '_self'
      //   )
      //   return
      // }
    },
    clearAllTableSort() {
      this.$refs.citationTable.clearSort()
      this.$refs.quoteTable.clearSort()
      this.$refs.articleTable.clearSort()
      this.$refs.institutionTable.clearSort()
    },
    // 排序的改变了
    handleChangeSort(prop, order, tableName) {
      if (tableName === 'quote') {
        // 聚类引用趋势列表排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.quoteTimeValue === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.quotePageObj.sort = sortValue
        this.getReferenceData()
      } else if (tableName === 'citation') {
        // 聚类引证趋势列表排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.citationTimeValue === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.citationPageObj.sort = sortValue
        this.getCitationData()
      } else if (tableName === 'influence') {
        // 影响因子列表排序改变
        let sortValue = ''
        if (order && order !== '') {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.influencePageObj.sort = sortValue
        this.getInfluenceData()
      } else if (tableName === 'institution') {
        // 机构发文统计排序改变
        let sortValue = ''
        if (!order || order === '') {
          sortValue = this.institutionTimeValue === 3 ? 'totalCount3,desc' : 'totalCount5,desc'
        } else {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.institutionPageObj.sort = sortValue
        this.getInstitutionData()
      } else if (tableName === 'article') {
        // 论文引证统计统计排序改变
        let sortValue = ''
        if (order && order !== '') {
          if (order === 'ascending') {
            sortValue = prop + ',asc'
          } else {
            sortValue = prop + ',desc'
          }
        }
        this.articlePageObj.sort = sortValue
        // this.getArticleData()
        this.citationInstitution()
      }
    },
    // 获得聚类引证统计数据
    async getCitationData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.citationPageObj.sort
      }
      GetCitationCluster(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.citationPageObj = {
            ...this.citationPageObj,
            page,
            size,
            total: totalElements
          }
          this.citationTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取引用统计数据
    async getReferenceData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.quotePageObj.sort
      }
      GetReferenceCluster(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.quotePageObj = {
            ...this.quotePageObj,
            page,
            size,
            total: totalElements
          }
          this.quoteTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 获取影响因子数据
    getInfluenceData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params =
        this.influencePageObj.sort === ''
          ? {
              magId: this.magId,
              page: page - 1,
              size
            }
          : {
              magId: this.magId,
              page: page - 1,
              size,
              sort: this.influencePageObj.sort
            }
      GetInfluence(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.influencePageObj = {
            ...this.influencePageObj,
            page,
            size,
            total: totalElements
          }
          this.influenceTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 发文机构统计数据请求
    getInstitutionData(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        type: this.institutionTypeValue,
        sort: this.institutionPageObj.sort
      }
      GetInstitution(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.institutionPageObj = {
            ...this.institutionPageObj,
            page,
            size,
            total: totalElements
          }
          this.institutionTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    },
    // 论文引证统计数据请求
    // getArticleData(pageParams = {}) {
    //   const { page = 1, size = 10 } = pageParams
    //   const params = {
    //     magId: this.magId,
    //     page: page - 1,
    //     size,
    //     type: this.articleTypeValue,
    //     sort: this.articlePageObj.sort
    //   }
    //   GetArticle(params).then((res) => {
    //     if (res && res.data && res.data.status === 0) {
    //       const { data } = res.data
    //       const { content = [], totalElements = 0 } = data
    //       this.articlePageObj = {
    //         ...this.articlePageObj,
    //         page,
    //         size,
    //         total: totalElements
    //       }
    //       this.articleTableData = content
    //     } else {
    //       const { message } = res.data
    //       this.$message.error(message)
    //     }
    //   })
    // },
    // 引证机构列表统计数据请求
    citationInstitution(pageParams = {}) {
      const { page = 1, size = 10 } = pageParams
      const params = {
        magId: this.magId,
        page: page - 1,
        size,
        sort: this.articlePageObj.sort
      }
      CitationInstitution(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [], totalElements = 0 } = data
          this.articlePageObj = {
            ...this.articlePageObj,
            page,
            size,
            total: totalElements
          }
          this.citationInstitutionTableData = content
        } else {
          const { message } = res.data
          this.$message.error(message)
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.con_01 {
  width: 376px;
  height: 110px;
  background: #f5f7fd;
  margin-bottom: 5px;
}

.conter_1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 0 25px;
}

.con_01_title {
  color: #666;
  font-size: 14px;
}

.conter_3 {
  padding: 15px 0 25px;
}

.tj_year {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  right: -10px;
}
.postion {
  position: absolute;
  top: 40px;
  left: 30px;
  z-index: 100;
}
.postion1 {
  position: absolute;
  top: 40px;
  right: 30px;
  z-index: 100;
}
.tj_year1 {
  color: #999;
  font-size: 14px;
  position: relative;
  top: -3px;
  right: -10px;
}
.linyu {
  display: flex;
}
.linyu ul li {
  line-height: 34px;
}
.linyu ul li a {
  color: #fff;
  font-size: 14px;
}
.ul1 {
  width: 242px;
}
.ul2 {
  width: 310px;
}
.ul3 {
  width: 262px;
}
.ul4 {
  flex: 1;
}
.centlin1 {
  position: absolute;
  top: 32px;
  right: 30px;
  z-index: 200;
}
.posion {
  position: relative;
}
.zk {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #fff;
}
.linyugd {
  float: left;
  width: 50%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.linyugd li {
  width: 50%;
  line-height: 34px;
  text-align: left;
}
.linyugd li a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
.linyugd li a:hover {
  color: rgba(255, 255, 255, 1);
}
.zk_title {
  width: 1200px;
  margin-left: -600px;
  color: #fff;
  position: fixed;
  top: 60px;
  left: 50%;
  display: flex;
  padding-bottom: 20px;
}
.zk_title div {
  width: 50%;
  text-align: left;
  padding-left: 26px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  line-height: 46px;
  border-bottom: 1px solid #fff;
}
</style>
