<template>
    <div class="sales salesHeader-box">
       <div class="w">
        <div class="header-box">
          <el-button class="position" plain @click="back()">返回</el-button>
          <p><label>刊物：</label>{{ tableData.magName }}</p>
          <p class="info">
            <span><label>年份：</label>{{ tableData.year }}</span>
            <span><label>刊期：</label>{{ tableData.issue }}</span>
            <span><label>数据源：</label>{{ tableData.dataSource }}</span>
          </p>
          <p class="info-oss"><label>OSS路径：</label>{{ tableData.ossPath }}</p>
        </div>
        <div class="flex-box">
          <ul>
            <li>数据中台论文<span>({{ len1 }})</span></li>
            <li v-for="(item, index) in tableData.dataCenterArticleList" :key="index" 
            :title="item.title"
            :class="item.markType ? 'bgyellow' : ''">
              {{ item.title }}
            </li>
          </ul>
          <ul>
            <li>资源中心<span>({{ len2 }})</span></li>
            <li v-for="(item, index) in tableData.resourceCenterArticleList" :key="index" :title="item.title">
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex'
  import { GetArticleComparison } from '@/api/collection'
  import Pagination from '@/components/Pagination'
  export default {
    name: 'Analysis',
    components: {
      Pagination
    },
    computed: {
      ...mapGetters({
        handleLoading: 'handleLoading',
        recentFiveYear: 'recentFiveYear',
        recentThreeYear: 'recentThreeYear',
        yearOptions: 'yearOptions'
      })
    },
    watch: {
      
    },
    data() {
      return {
          tableData: [],
          len1: 0,
          len2: 0
      }
    },
    created(){
      this.getArticleComparison()
    },
    mounted() {
    },
    methods: {
      back(){
        this.$router.push({ path: '/statistics', query: { id: this.$route.query.id } }) // 跳转地址
      },
      // 采集任务平台-数据源列表
      getArticleComparison(){
        const params = {
          dataSourceId: this.$route.query.dataSourceId,
          magId: this.$route.query.magId,
          year: this.$route.query.year,
          issue: this.$route.query.issue
        }
        GetArticleComparison(params).then((res) => {
          let {data, status} = res.data
          if(status==0){
            this.tableData = data
            this.len1 = this.tableData.dataCenterArticleList.length
            this.len2 = this.tableData.resourceCenterArticleList.length
            if (this.len1 > this.len2) {
              for(let i = 0; i < this.len1 - this.len2; i++) {
                this.tableData.resourceCenterArticleList.push({
                  title: '',
                  markType: ''
                })
              }
            } else if (this.len1 < this.len2) {
              for(let i = 0; i < this.len2 - this.len1; i++) {
                this.tableData.dataCenterArticleList.push({
                  title: '',
                  markType: ''
                })
              }
            }
          }else{
            const { message = '' } = res.data
            this.$message.error(message)
          }
        })
      },
      // 页码改变了之后的操作
      handleChangePageData (pageObj) {
         this.initObj.page = pageObj.page
         this.initObj.size = pageObj.size
        this.getOperationLogs()
      },
    }
  }
  </script>
  <style scoped lang="scss">
  .position {
      position: absolute;
      top: 20px;
      left: 30px;
  }

.header-box {
    position: relative;
    width: 1200px;
    background: #eee;
    border: 1px solid #ccc;
    text-align: left;
    padding: 20px 200px;
    box-sizing: border-box;
    p {
        font-size: 18px;
        line-height: 36px;
        font-weight: 700;
        span {
            margin-right: 40px;
        }
        label{
            font-weight: 400;
            color: #666;
        }
    }
}
.flex-box {
  display: flex;
  // border: 1px solid #ccc;
  // border-bottom: none;
  // border-right: none;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  background: #fff;
  ul {
    width: 50%;
    li {
      border: 1px solid #ddd;
      border-top: none;
      height: 40px;
      line-height: 40px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      padding: 0 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &:first-child{
        text-align: center;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .bgyellow {
      background: #FEFA83;
    }
    &:last-child{
      li {
        border-left: none;
      }
    }
  }
}
  </style>
  