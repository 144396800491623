var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.handleLoading),expression:"handleLoading"}],staticClass:"w center_all"},[_c('div',{staticClass:"center_1 posion"},[_c('Overview',{on:{"onAnchorScroll":_vm.handleAnchorScroll}}),_c('div',[_c('h2',{staticClass:"center_title"},[_vm._v(" 期刊聚类引证趋势 "),_c('div',{staticClass:"fr btn"},[_c('el-radio-group',{model:{value:(_vm.citationTimeValue),callback:function ($$v) {_vm.citationTimeValue=$$v},expression:"citationTimeValue"}},_vm._l((_vm.timeOptions),function(item){return _c('el-radio-button',{key:item.key,attrs:{"label":item.key}},[_vm._v(_vm._s(item.value))])}),1)],1)]),_c('SimpleTable',{ref:"citationTable",attrs:{"is-highlight":true,"popContent":_vm.popContent,"tableData":_vm.citationTableData,"columnData":_vm.citationTimeValue === 3 ? _vm.citationColumnThree : _vm.citationColumn,"initObj":_vm.citationPageObj},on:{"onChangeSort":(prop, order) => {
            _vm.handleChangeSort(prop, order, 'citation')
          },"onChangePageData":(pageObj) => {
            _vm.getCitationData(pageObj)
          },"onClickTitle":(item) => {
            _vm.handleClusterDetail(item, 'citation')
          },"onClickPopIcon":(item) => {
            _vm.handleSearchClusterList(item)
          }}})],1),_c('div',[_c('h2',{staticClass:"center_title"},[_vm._v(" 期刊聚类引用趋势 "),_c('div',{staticClass:"fr btn"},[_c('el-radio-group',{model:{value:(_vm.quoteTimeValue),callback:function ($$v) {_vm.quoteTimeValue=$$v},expression:"quoteTimeValue"}},_vm._l((_vm.timeOptions),function(item){return _c('el-radio-button',{key:item.key,attrs:{"label":item.key}},[_vm._v(_vm._s(item.value))])}),1)],1)]),_c('SimpleTable',{ref:"quoteTable",attrs:{"is-highlight":true,"popContent":_vm.popContent,"tableData":_vm.quoteTableData,"columnData":_vm.quoteTimeValue === 3 ? _vm.quoteColumnThree : _vm.quoteColumn,"initObj":_vm.quotePageObj},on:{"onChangeSort":(prop, order) => {
            _vm.handleChangeSort(prop, order, 'quote')
          },"onChangePageData":(pageObj) => {
            _vm.getReferenceData(pageObj)
          },"onClickTitle":(item) => {
            _vm.handleClusterDetail(item, 'quote')
          },"onClickPopIcon":(item) => {
            _vm.handleSearchClusterList(item)
          }}})],1),_c('div',[_c('h2',{staticClass:"center_title"},[_vm._v(" 影响因子 "),_c('div',{staticClass:"fr btn"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-xz"},on:{"click":() => {
                _vm.handleDownload('influence')
              }}},[_vm._v("下载")])],1)]),_c('SimpleTable',{attrs:{"tableData":_vm.influenceTableData,"columnData":_vm.influenceColumn,"initObj":_vm.influencePageObj},on:{"onChangeSort":(prop, order) => {
            _vm.handleChangeSort(prop, order, 'influence')
          },"onChangePageData":(pageObj) => {
            _vm.getInfluenceData(pageObj)
          },"onClickTitle":(item) => {
            _vm.handleClusterDetail(item, 'influence')
          }}})],1),_c('div',{attrs:{"id":"magInstitutionCount"}},[_c('h2',{staticClass:"center_title"},[_vm._v(" 机构发文统计 "),_c('div',{staticClass:"fr btn"},[_c('el-select',{staticStyle:{"width":"100px","top":"-4px"},attrs:{"placeholder":"请选择"},model:{value:(_vm.institutionTypeValue),callback:function ($$v) {_vm.institutionTypeValue=$$v},expression:"institutionTypeValue"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-radio-group',{model:{value:(_vm.institutionTimeValue),callback:function ($$v) {_vm.institutionTimeValue=$$v},expression:"institutionTimeValue"}},_vm._l((_vm.timeOptions),function(item){return _c('el-radio-button',{key:item.key,attrs:{"label":item.key}},[_vm._v(_vm._s(item.value))])}),1),_c('el-button',{attrs:{"type":"text","icon":"el-icon-xz"},on:{"click":() => {
                _vm.handleDownload('institution')
              }}},[_vm._v("下载")])],1)]),_c('SimpleTable',{ref:"institutionTable",attrs:{"tableData":_vm.institutionTableData,"columnData":_vm.institutionTimeValue === 3 ? _vm.institutionColumnThree : _vm.institutionColumn,"initObj":_vm.institutionPageObj},on:{"onChangeSort":(prop, order) => {
            _vm.handleChangeSort(prop, order, 'institution')
          },"onChangePageData":(pageObj) => {
            _vm.getInstitutionData(pageObj)
          }}})],1),_c('div',{attrs:{"id":"magArticleCount"}},[_c('h2',{staticClass:"center_title"},[_vm._v(" 引证机构列表统计 "),_c('div',{staticClass:"fr btn"},[_c('el-radio-group',{model:{value:(_vm.articleTimeValue),callback:function ($$v) {_vm.articleTimeValue=$$v},expression:"articleTimeValue"}},_vm._l((_vm.timeOptions),function(item){return _c('el-radio-button',{key:item.key,attrs:{"label":item.key}},[_vm._v(_vm._s(item.value))])}),1)],1)]),_c('SimpleTable',{ref:"articleTable",attrs:{"tableData":_vm.citationInstitutionTableData,"columnData":_vm.articleTimeValue === 3 ? _vm.articleColumnThree : _vm.articleColumn,"initObj":_vm.articlePageObj},on:{"onChangeSort":(prop, order) => {
            _vm.handleChangeSort(prop, order, 'article')
          },"onChangePageData":(pageObj) => {
            _vm.citationInstitution(pageObj)
          }}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }