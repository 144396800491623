<template>
    <div class="sales salesHeader-box">
        <div class="w center_all">
            <div class="center_1 title">详情</div>
            <div class="center_1 posion">
                <el-form  ref="form" :model="form" label-width="120px" size="mini" style="text-align: left;">
                    <el-form-item label="期刊名称" prop="magName">
                        <div>{{form.magName}}</div>
                    </el-form-item>
                    <el-form-item label="申请时间" prop="createTime">
                        <div>{{form.createTime}}</div>
                    </el-form-item>
                    <el-form-item label="计划完成时间" prop="planCompletedTime">
                        <div>{{form.planCompletedTime}}</div>
                    </el-form-item>
                    <el-form-item label="当前最新刊期" prop="latestIssue">
                        <div>{{form.latestIssue}}</div>
                    </el-form-item>
                    <el-form-item label="预测当前刊期" prop="predictedIssue">
                        <div>{{form.predictedIssue}}</div>
                    </el-form-item>
                    <el-form-item label="采集周期" prop="taskTrigger">
                        <div>{{form.taskTrigger}}</div>
                    </el-form-item>
                    <el-form-item label="工单地址" prop="taskUrl">
                        <div>{{form.taskUrl}}</div>
                    </el-form-item>
                    <el-form-item label="网站地址" prop="siteUrl">
                        <div>{{form.siteUrl}}</div>
                    </el-form-item>
                    <el-form-item label="统计结果" prop="magName">
                        <div>{{form.magName}}</div>
                    </el-form-item>
                    <div style="display:flex;">
                        <el-form-item label="数据源" style="width:20%;" prop="dataSource">
                            <div>{{form.dataSource}}</div>
                        </el-form-item>
                        <el-form-item label="研发人员" style="width:20%;margin-left: 10%;" prop="rdUserName">
                            <div>{{form.rdUserName}}</div>
                        </el-form-item>
                        <el-form-item label="服务人员" style="width:20%;margin-left: 10%;" prop="createUserName">
                            <div>{{form.createUserName}}</div>
                        </el-form-item>
                    </div>
                    <el-form-item label="校验配置">
                        <div>
                           <span v-for="(item, index) in form.checkConfig" :key="index">{{item}} </span>
                        </div>
                    </el-form-item>
                    <el-form-item label="出版周期">
                        <div>{{form.publishPeriod}}</div>
                    </el-form-item>
                    <el-form-item label="知网采集周期">
                        <div>{{form.taskTrigger}}</div>
                    </el-form-item>
                    <el-form-item label="上次采集时间">
                        <div>{{form.lastCrawlTime}}</div>
                    </el-form-item>
                    <div style="display:flex;">
                        <el-form-item label="优先级">
                           <div>{{form.priority}}</div>
                        </el-form-item>
                        <el-form-item label="强制采集" style="text-align: left;">
                            <div>{{form.forceRepeatFlag?'开启':'关闭'}}</div>
                        </el-form-item>
                    </div>
                    <el-form-item label="预计完成时间" style="text-align: left;" v-if="isShow&&role==6">
                        <el-col :span="11">
                            <div>{{form.planCompletedTime}}</div>
                        </el-col>
                    </el-form-item>
                    <div style="display:flex;">
                        <el-form-item label="状态" style="text-align: left; width:27%" v-if="isShow">
                            <div>{{form.statusId}}</div>
                        </el-form-item>
                        <el-form-item label="预测机制" style="text-align: left;">
                            <div>{{form.predictedConfig}}</div>
                        </el-form-item>
                    </div>
                    <el-form-item style="width:100%;text-align:center" label-width="0">
                        <!-- <el-button type="primary" @click="submitForm('form')">{{isShow?'编辑':'新建'}}</el-button> -->
                        <el-button @click="cancellation()">返回</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { GetCheckConfig, GetdataSourceList, GetMemberList, GetQueryMag, PostEdit, GetInfo, GetStatusList } from '@/api/collection'
import Pagination from '@/components/Pagination'
export default {
  name: 'Analysis',
  components: {
    Pagination
  },
  computed: {
    ...mapGetters({
      handleLoading: 'handleLoading',
      recentFiveYear: 'recentFiveYear',
      recentThreeYear: 'recentThreeYear',
      yearOptions: 'yearOptions'
    })
  },
  watch: {
    
  },
  data() {
    return {
        role: '',
        checkData:[], // 校验配置
        sourceOptions:[], // 数据源
        memberOptions:[], // 指派人-采集人员
        statusOptions:[], // 状态列表
        form: {
          magName: '',
          taskUrl: '',
          siteUrl: '',
          dataSourceId: '',
          rdUserId: '',
          publishPeriod: '',
          lastCrawlTime: '',
          priority: '',
          forceRepeatFlag: true,
          planCompletedTime: '',
          statusId: '',
          predictedConfig: '',
          checkConfig: []
        },
        restaurants: [], // 搜索结果
        isShow: false // 是否显示， 新建不显示/编辑显示
    }
  },
  created(){
    this.role = sessionStorage.getItem('role')
    this.getStatusList()
    this.getInfo()
    this.getMemberList()
    this.getdataSourceList()
    this.getCheckConfig()
  },
  mounted() {
  },
  methods: {
    // 采集任务平台-状态列表
    getStatusList(){
        let params = {}
        GetStatusList(params).then((res) => {
            let {data, status} = res.data
            if(status==0){
                this.statusOptions = data
            } else {
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
    },
    // 采集任务平台-任务详情
    getInfo(){
      let params = {id:this.$route.query.id}
      GetInfo(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
            console.log(data)
            let checkConfigData = []
            let checkConfig = JSON.parse(data.checkConfig)
            checkConfig.forEach(item=>{
                if(item.status == 1){
                    checkConfigData.push(item.name)
                } 
            })
            this.form = data
            this.form.checkConfig = checkConfigData
            this.form.forceRepeatFlag = data.forceRepeatFlag==1?true:false
            // this.memberOptions = cj
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 提交表单
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
            if (valid) {
                let params = {}
                let magId = ''
                let dataSourceName = ''
                let memberName = ''
                let status = ''
                let checkConfig = []
                let form = this.form
                for(let i in form){
                    if(i === 'magName'){
                        this.restaurants.forEach(item =>{
                            if(form[i] === item.magName){
                                magId = item.magId
                            }
                        })                        
                    }
                    if(i === 'dataSourceId'){
                        this.sourceOptions.forEach(item =>{
                            if(form[i] === item.dataSourceId){
                                dataSourceName = item.dataSourceName
                            }
                        })
                    }
                    if(i === 'rdUserId'){
                        this.memberOptions.forEach(item =>{
                            if(form[i] === item.memberId){
                                memberName = item.memberName
                            }
                        })
                    }
                    if(i === 'checkConfig'){
                        this.checkData.forEach(item =>{                        
                            if(form[i].includes(item.name)){
                                checkConfig.push(item)
                            }
                        })
                    }
                    if(i === 'statusId'){
                        this.statusOptions.forEach(item =>{
                            if(form[i] === item.id){
                                status = item.course
                            }
                        })
                    }
                }
                if(this.$route.path === '/add'){
                    // 新建
                    params = {
                        magId: magId, //期刊ID 必传
                        magName: form.magName, //刊物名称 必传
                        predictedConfig:form.predictedConfig, //预测配置 非必传
                        dataSourceId: form.dataSourceId, //数据源ID 必传
                        dataSource: dataSourceName, //数据源 必传
                        priority: form.priority, //优先级，1为最低，10位最大 非必传
                        forceRepeatFlag:form.forceRepeatFlag?1:0, //强制重采标识，0不强制，1为强制 非必传
                        // taskTrigger:'', //采集间隔 非必传
                        taskUrl:form.taskUrl, //工单地址 非必传
                        siteUrl:form.siteUrl, //网站地址 非必传
                        // status:'', //流程状态 非必传
                        // statusId:'', //流程状态ID 必传
                        rdUserId:form.rdUserId, //指派给人员Id 必传
                        rdUserName:memberName, //指派给人员姓名 必传
                        checkConfig: JSON.stringify(checkConfig), //校验配置 必传
                    }
                }else if(this.$route.path === '/edit'){
                    // 编辑
                    params = {
                        id: Number(this.$route.query.id), // 任务id，新建时不要传，编辑是必传
                        magId: form.magId, //期刊ID 必传
                        magName: form.magName, //刊物名称 必传
                        publishPeriod: form.publishPeriod, //出版周期，可编辑，新建时不用传
                        latestIssue: form.latestIssue, //当前最新刊期，可编辑，新建时不用传
                        predictedIssue: form.predictedIssue, //预测当前刊期，可编辑，新建时不用传
                        predictedConfig: form.predictedConfig, //预测配置 非必传
                        dataSourceId: form.dataSourceId, //数据源ID 必传
                        dataSource: dataSourceName, //数据源 必传
                        lastCrawlTime: form.lastCrawlTime, //上次采集时间 非必传
                        priority: form.priority, //优先级，1为最低，10位最大 非必传
                        forceRepeatFlag: form.forceRepeatFlag?1:0, //强制重采标识，0不强制，1为强制 非必传
                        taskTrigger: form.taskTrigger, //采集间隔 非必传
                        taskUrl:form.taskUrl, //工单地址 非必传
                        siteUrl:form.siteUrl, //网站地址 非必传
                        status: status, //流程状态 非必传
                        statusId: form.statusId, //流程状态ID 必传
                        rdUserId: form.rdUserId, //指派给人员Id 必传
                        rdUserName: memberName, //指派给人员姓名 必传
                        checkConfig: JSON.stringify(checkConfig), //校验配置 必传
                        planCompletedTime: form.planCompletedTime //计划完成时间 非必传
                    }
                }
                PostEdit(params).then((res) => {
                    if (res && res.data && res.data.status === 0) {
                        this.$message.success('新建成功')
                        setTimeout(()=>{
                            this.$router.push('/collection').catch((err) => {err})
                        }, 800)
                    }
                })
            } else {
                console.log('error submit!!');
            return false;
            }
        });
    },
    // 重置表单
    resetForm(formName) {
        this.$refs[formName].resetFields();
    },
    // 取消
    cancellation(){
       this.$router.push({ path: '/collection', query: { } }) // 跳转地址
    },
    // 采集任务平台-获取指派人列表-用采集人员
    getMemberList(){
      let params = {}
      GetMemberList(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
            let cj =  []
            data.forEach(item => {
                if(item.roleName === '采集人员'){
                    cj = item.roleList
                }
            });
            this.memberOptions = cj
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 采集任务平台-数据源列表
    getdataSourceList(){
      let params = {}
      GetdataSourceList(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          this.sourceOptions = data
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 采集任务平台-校验配置列表
    getCheckConfig(pageParams = {}) {
      const params = {}
      GetCheckConfig(params).then((res) => {
        let {data, status} = res.data
        if(status==0){
          this.checkData = data          
          console.log(this.checkData)
        }else{
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 远程搜索
    querySearchAsync(queryString, cb) {
        console.log(queryString)
        console.log(cb)
        let params = {
            keyWords: queryString
        }
        // var restaurants = this.restaurants;
        var results = [] // queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
        // 采集任务平台-期刊联想查询
        GetQueryMag(params).then((res) => {
            let {data, status} = res.data
            if(status==0){
                data.forEach(item=>{
                    results.push({
                        value:item.magName,
                        magName:item.magName,
                        magId: item.magId,
                    })
                })
                this.restaurants = results
                cb(results)
            }else{
                const { message = '' } = res.data
                this.$message.error(message)
            }
        })
        // clearTimeout(this.timeout);
        // this.timeout = setTimeout(() => {
        //     cb(results);
        // }, 3000 * Math.random());
    }    
  }
}
</script>
<style scoped lang="scss">
  .w1{
    width: 100%;
  }
  .center_1{
    .el-form-item{
        width: 50%;
    }
    .el-checkbox-group{
        display: flex;
        flex-wrap: wrap;
        width: 800px;
        .el-checkbox{
            width: 100px;
            text-align: left;
        }
    }
  }
  .title{
      font-size: 20px;
      font-weight: bold;
  }
</style>
