import request from './services/axios.js'

// 采集任务平台-任务列表
export function Getlist(params) {
  return request({
    params,
    url: '/vas/gather/list',
    method: 'get'
  })
}
// 采集任务平台-期刊列表
export function GetmagList(params) {
  return request({
    params,
    url: '/vas/gather/magList',
    method: 'get'
  })
}
// 采集任务平台-状态列表
export function GetstatusList(params) {
  return request({
    params,
    url: '/vas/gather/statusList',
    method: 'get'
  })
}
// 采集任务平台-数据源列表
export function GetdataSourceList (params) {
  return request({
    params,
    url: '/vas/gather/dataSourceList',
    method: 'get'
  })
}
// 采集任务平台-校验配置列表
export function GetCheckConfig (params) {
  return request({
    params,
    url: '/vas/gather/getCheckConfig',
    method: 'get'
  })
}
// 采集任务平台-获取指派人列表
export function GetMemberList (params) {
  return request({
    params,
    url: '/vas/gather/memberList',
    method: 'get'
  })
}
// 采集任务平台-期刊联想查询
export function GetQueryMag (params) {
  return request({
    params,
    url: '/vas/flow/queryMag',
    method: 'get'
  })
}
// 采集任务平台-新建编辑
export function PostEdit(params) {
  return request({
    data: params,
    url: '/vas/gather/edit',
    method: 'post'
  })
}
// 采集任务平台-任务详情
export function GetInfo(params) {
  return request({
    params,
    url: '/vas/gather/info',
    method: 'get'
  })
}
// 采集任务平台-日志记录
export function GetOperationLogs(params) {
  return request({
    params,
    url: '/vas/gather/operationLogs',
    method: 'get'
  })
}
// 采集任务平台-状态列表
export function GetStatusList(params) {
  return request({
    params,
    url: '/vas/gather/statusList',
    method: 'get'
  })
}
// 采集任务平台-通知
export function PostNotice(params) {
  return request({
    params,
    url: '/vas/gather/notice',
    method: 'post'
  })
}
// 采集任务平台-通知
export function PostAssignTask(params) {
  return request({
    params,
    url: '/vas/gather/assignTask',
    method: 'post'
  })
}
// 采集任务平台-调用统计
export function GetArticleStatistic(params) {
  return request({
    params,
    url: '/vas/gather/articleStatistic',
    method: 'get'
  })
}
// 采集任务平台-调用统计
export function GetClusterYearIssue(params) {
  return request({
    params,
    url: '/vas/gather/clusterYearIssue',
    method: 'get'
  })
}
// 采集任务平台-数据比对
export function GetArticleComparison (params) {
  return request({
    params,
    url: '/vas/gather/articleComparison',
    method: 'get'
  })
}
// 采集任务平台-论文列表
export function GetArticlePage (params) {
  return request({
    params,
    url: '/vas/gather/articlePage',
    method: 'get'
  })
}
// 采集任务平台-出版周期列表
export function GetPublishPeriodList (params) {
  return request({
    params,
    url: '/vas/gather/publishPeriodList',
    method: 'get'
  })
}
